import { useMemo } from "react";
import {
  Appointment,
  AppointmentFilterSet,
  AppointmentSortField,
  Instant,
  type InstantRange,
  ReferenceFilter,
  SortField,
  ZonedDateTime
} from "@remhealth/apollo";
import { instantRangeOverlaps, isInstantRangeEffective } from "@remhealth/host";
import { useStore } from "./useStore";
import { matchesReferenceFilter } from "./filters";

export interface AppointmentFilterOptions {
  ownerId?: string;
  attendees?: ReferenceFilter[];
  isGroup?: boolean;
  overlaps?: InstantRange;
  wraps?: Instant;
  before?: Instant;
  onOrAfter?: Instant;
}

export function useAppointmentsView(sort: SortField<AppointmentSortField>, options: AppointmentFilterOptions) {
  const store = useStore();

  return useMemo(() => {
    return store.appointments.view({
      filters: {
        online: createAppointmentFilters(options),
        offline: s => offlinefilter(s, options),
      },
      orderBy: {
        online: sort,
        offline: sortOffline(sort),
      },
      feedOptions: options.ownerId && options.ownerId.length > 0
        ? { partition: options.ownerId }
        : { crossPartition: true },
    });
  }, [JSON.stringify([options, sort])]);
}

export function createAppointmentFilters(options: AppointmentFilterOptions) {
  const ownerFilter: AppointmentFilterSet = !options.ownerId ? {} : {
    owner: {
      in: [options.ownerId],
    },
  };

  const startFilter: AppointmentFilterSet = !options.before && !options.onOrAfter && !options.wraps ? {} : {
    start: { before: options.before, onOrAfter: options.onOrAfter || options.wraps },
  };

  const wrapsFilter: AppointmentFilterSet = !options.wraps ? {} : {
    end: { onOrBefore: options.wraps },
  };

  const isGroupFilter: AppointmentFilterSet = options.isGroup === undefined ? {} : {
    isGroup: { equalTo: options.isGroup, presence: options.isGroup ? "MustBePresent" : "MaybePresent" },
  };

  let filterSets: AppointmentFilterSet[] = [{
    ...ownerFilter,
    ...startFilter,
    ...wrapsFilter,
    ...isGroupFilter,
  }];

  if (options.attendees && options.attendees.length > 0) {
    filterSets = options.attendees.flatMap(attendeeFilter => filterSets.map(filterSet => ({
      ...filterSet,
      attendee: attendeeFilter,
    })));
  }

  if (options.overlaps?.start && options.overlaps?.end) {
    const overlapsFilters: AppointmentFilterSet[] = [
      {
        start: { onOrBefore: options.overlaps.start },
        end: { onOrAfter: options.overlaps.start },
      },
      {
        start: { onOrBefore: options.overlaps.end },
        end: { onOrAfter: options.overlaps.end },
      },
      {
        start: { onOrAfter: options.overlaps.start },
        end: { onOrBefore: options.overlaps.end },
      },
    ];

    filterSets = overlapsFilters.flatMap(overlapsFilter => filterSets.map(filterSet => ({
      ...filterSet,
      ...overlapsFilter,
    })));
  }

  return filterSets;
}

function offlinefilter(appointment: Appointment, options: AppointmentFilterOptions) {
  const { attendees, ownerId, before, onOrAfter, overlaps, wraps, isGroup } = options;

  if (ownerId && appointment.owner.id !== ownerId) {
    return false;
  }

  if (attendees && attendees.length > 0) {
    const attendeeIds = new Set(appointment.attendees.map(i => i.subject.id));

    if (!attendees.some(filter => matchesReferenceFilter(attendeeIds, filter))) {
      return false;
    }
  }

  if (isGroup !== undefined && appointment.isGroup !== isGroup) {
    return false;
  }

  const appointmentStart = ZonedDateTime.toDate(appointment.start);

  if (before && appointmentStart >= Instant.toDate(before)) {
    return false;
  }

  if (onOrAfter && appointmentStart < Instant.toDate(onOrAfter)) {
    return false;
  }

  if (wraps || overlaps) {
    const appointmentEnd = ZonedDateTime.toDate(appointment.end);
    const appointmentRange: InstantRange = { start: Instant.fromDate(appointmentStart), end: Instant.fromDate(appointmentEnd) };

    if (wraps) {
      if (!isInstantRangeEffective(appointmentRange, Instant.toDate(wraps))) {
        return false;
      }
    }

    const wrapsDate = Instant.toDate(wraps);
    if (wrapsDate && (appointmentStart < wrapsDate || appointmentEnd > wrapsDate)) {
      return false;
    }

    if (overlaps) {
      if (!instantRangeOverlaps(appointmentRange, overlaps, false)) {
        return false;
      }
    }
  }

  return true;
}

function sortOffline(sort: SortField<AppointmentSortField>) {
  const dir = sort.direction === "Ascending" ? 1 : -1;
  return (a: Appointment, b: Appointment) => {
    switch (sort.field) {
      case "Start":
      default:
        return dir * (a.start ?? "").localeCompare(b.start ?? "");
    }
  };
}
