import { useProductFlag, useReleaseCheck } from "@remhealth/host";
import { useEhr, useStore } from "@remhealth/core";
import { useAbort } from "@remhealth/ui";
import {
  Approximate,
  NoteRule,
  PatientNote
} from "@remhealth/apollo";
import { useNoteRulesFeed } from "../rules/useNoteRulesFeed";

export interface NoteAuthorizationCheckResult {
  authorizationRules: NoteRule[];
  blockSign: boolean;
}

export function useNoteAuthorizationCheck() {
  const abort = useAbort();
  const store = useStore();
  const ehrService = useEhr();

  const showAuthorizationRule = useProductFlag("Authorizations");
  const isMyAvatarAvatarAuthorizationsReleased = useReleaseCheck("myAvatarAuthorizations");

  const loadAuthorizationRules = useNoteRulesFeed({
    criteriaTypes: ["Authorization"],
  });

  return { noteAuthorizationCheck, allowNoteAuthorizationCheck };

  async function allowNoteAuthorizationCheck(note: PatientNote) : Promise<boolean> {
    if (!showAuthorizationRule || !isMyAvatarAvatarAuthorizationsReleased) {
      return false;
    }

    const authorizationRules = await loadAuthorizationRules();
    if (authorizationRules.length === 0) {
      return false;
    }

    const definition = await store.noteDefinitions.expand(note.definition, { abort: abort.signal });
    if (!definition.billable) {
      return false;
    }

    const encounter = await store.encounters.expand(note.encounter, { abort: abort.signal });
    if (!encounter.episodeOfCare || !encounter.serviceType || !encounter.period.start) {
      return false;
    }

    return authorizationRules.some(rule => rule.limitToServices.some(s => s.id === encounter.serviceType?.id));
  }

  async function noteAuthorizationCheck(note: PatientNote): Promise<NoteAuthorizationCheckResult> {
    if (!await allowNoteAuthorizationCheck(note)) {
      return { authorizationRules: [], blockSign: false };
    }

    const encounter = await store.encounters.expand(note.encounter, { abort: abort.signal });
    const authorizationRules = await loadAuthorizationRules();
    const serviceRules = authorizationRules.filter(rule => rule.limitToServices.some(s => s.id === encounter.serviceType?.id));
    if (serviceRules.length === 0) {
      return { authorizationRules: [], blockSign: false };
    }

    const authorization = await ehrService.searchAuthorizations(
      note.subject.id,
      encounter.episodeOfCare!.id,
      encounter.serviceType!.id,
      Approximate.toDate(encounter.period!.start!),
      abort.signal
    );

    const unmetRules = serviceRules.filter(rule => {
      if (rule.criteria.type !== "Authorization") {
        return;
      }

      const count = authorization?.remainingVisits ?? (rule.criteria.treatMissingAsZero ? 0 : undefined);
      if (count === undefined || count >= rule.criteria.minimum) {
        return;
      }

      return rule;
    });

    const blockSign = unmetRules.some(r => r.outcome.type === "Blocker");

    return { authorizationRules: unmetRules, blockSign };
  }
}
