import { VisitStatus } from "@remhealth/apollo";
import { ChevronRight } from "@remhealth/icons";
import { Icon } from "@remhealth/ui";
import { Labeling } from "~/labeling";

// Application features/headers
export const BellsCommands = "Bells Commands";
export const Mention = "T@g";
export const Mentions = "T@gs";
export const NoSnippets = "No drop-ins";
export const PersonalDictionary = "Personal Dictionary";
export const PersonalSettings = "Personal Settings";
export const Snippet = "Drop-In";
export const Snippets = "Drop-Ins";
export const Substitutions = "Substitutions";
export const TextExpansion = "Expansion";
export const TextExpansions = "Expansions";
export const MyProfile = "My Profile";

// Buttons
export const Add = "Add";
export const Apply = "Apply";
export const Cancel = "Cancel";
export const ClearAll = "Clear All";
export const Confirm = "Confirm";
export const Copy = "Copy";
export const CopyPassword = "Copy Password";
export const CreateSnippet = "Create a new drop-in";
export const Delete = "Delete";
export const Done = "Done";
export const Edit = "Edit";
export const Enroll = "Enroll";
export const Finish = "Finish";
export const Finished = "Finished";
export const LastSevenDays = "Last 7 Days";
export const LastThirtyDays = "Last 30 Days";
export const LastNinetyDays = "Last 90 Days";
export const MakeExpansion = "Make expansion";
export const MakeSnippet = "Make drop-in";
export const NewButton = (type: string) => `New ${type}`;
export const Okay = "Okay";
export const Print = "Print";
export const Preview = "Preview";
export const Remove = "Remove";
export const Resync = "Resync";
export const Save = (label: string) => `Save ${label}`;
export const Share = "Share";
export const Sign = "Sign";

// Confirm button
export const ConfirmDelete = "Yes, delete";
export const ConfirmLeave = "Yes, leave";
export const ConfirmDiscard = "Yes, discard";
export const ConfirmRemove = "Yes, remove";
export const ConfirmRestore = "Yes, restore";

// Commands
export const ActionsCommandGroup = "Actions";
export const BlocksCommandGroup = "Blocks";
export const BulletedList = "Bulleted List";
export const BulletedListCommandDescription = `Create a ${BulletedList.toLowerCase()}.`;
export const DateCommandDescription = "Pick a date to drop-in.";
export const InlineCommandGroup = "Inline";
export const MentionCommandDescription = `Search for and ${Mention.toLowerCase()} a person.`;
export const NumberedList = "Numbered List";
export const NumberedListCommandDescription = `Create a ${NumberedList.toLowerCase()}.`;
export const Placeholders = "Placeholders";
export const TaskList = "Task List";
export const TaskListCommandDescription = `Create a ${TaskList.toLowerCase()}.`;

// General
export const No = "No";
export const Yes = "Yes";

// Labels
export const Active = "Active";
export const Adjectives = "Adjectives";
export const AllPatientsDisabled = (labels: Labeling) => `All ${labels.Patients} Disabled`;
export const AllPatientsEnabled = (labels: Labeling) => `All ${labels.Patients} Enabled`;
export const Alternatives = "Alternatives";
export const AmbientListening = "Ambient Listening";
export const AmbientListeningDisabled = "Ambient Listening Disabled";
export const AmbientListeningEnabled = "Ambient Listening Enabled";
export const Archive = "Archive";
export const Archived = "Archived";
export const Assignment = "Assignment";
export const Attendees = "Attendees";
export const AuditFindings = "Audit Findings";
export const Available = "Available";
export const AvailableForGoalTracking = "Available for Goal Tracking";
export const AvailableLocations = (labels: Labeling) => `Available ${labels.Locations}`;
export const BellsAdmins = "Bells Admin";
export const BellsDisabled = "Bells Disabled";
export const BellsEnabled = "Bells Enabled";
export const BellsNonAdmins = "Bells Non-Admin";
export const CareTeam = "Care Team";
export const CancelIgnoreConflict = "No, let me fix the conflicts";
export const Cancelled = "Cancelled";
export const ClearPin = "Clear signature pin";
export const ClinicalRecommendation = "Clinical Recommendation";
export const ClinicalRecommendations = "Clinical Recommendations";
export const CoPractitioner = "Co-Practitioner";
export const Code = "Code";
export const Completed = "Completed";
export const Configure = "Configure";
export const ConfirmClearPin = "Yes, clear the pin";
export const ConfirmIgnoreConflict = "Yes, ignore conflicts";
export const Conflict = "Conflict";
export const Created = "Created";
export const CreatePin = "Create Pin";
export const Date = "Date";
export const Description = "Description";
export const DefaultLocationRoleLabel = (labels: Labeling) => `Select default Place of Service for this ${labels.Location}.`;
export const Draft = "Draft";
export const Effective = "Effective";
export const ExternallyFulfilled = "Externally Fulfilled";
export const ExpandOnce = "Expand only first occurrence in the note";
export const Expansions = "Expansions";
export const Expiration = "Expiration";
export const Filter = "Filter";
export const Filters = "Filters";
export const Final = "Final";
export const Fulfilled = "Fulfilled";
export const FullText = "Full Text";
export const GroupAdministrator = "Group Administrator";
export const Administrator = "Administrator";
export const HireDate = "Hire Date";
export const IgnoreService = "Ignore service";
export const Image = "Image";
export const Inactive = "Inactive";
export const InheritServices = "Inherit services";
export const InheritVisitStatus = (labels: Labeling) => `Inherit ${labels.session} statuses`;
export const Instructions = "Instructions";
export const Issue = "Issue";
export const LastSignedIn = "Last Signed In Date";
export const LastUpdated = "Last Update";
export const ManageUsers = "Manage Users";
export const Members = "Members";
export const MinutesOnly = "Minutes only";
export const More = "More";
export const Name = "Name";
export const New = "New";
export const NiamEnabled = "NIAM Enabled";
export const NoLabelSelected = (label: string) => `No ${label} selected`;
export const NoShow = "No Show";
export const NoUsers = "No Users";
export const NotConfigured = "Not Configured";
export const NotStarted = "Not Started";
export const NoteOwner = "Author";
export const NoteOwners = "Author(s)";
export const NoteTypesConfigured = "Note Types Configured";
export const OnlyNoteTypesCopy = "Only the note types will be copied";
export const Organization = "Organization";
export const OrganizationLevel = "Organization-Level";
export const OverrideDefaultLocationRoleLabel = (labels: Labeling) => `Override ${labels.Product} Place of Service defaults`;
export const EnrollmentProgramLabel = (labels: Labeling) => `${labels.Enrollment} (Program)`;
export const Pin = "Pin";
export const Program = "Program";
export const Programs = "Programs";
export const PatientImported = (labels: Labeling) => `${labels.Patient} imported`;
export const PatientAlreadyImported = (labels: Labeling) => `${labels.Patient} already imported`;
export const PatientView = (labels: Labeling) => `${labels.Patient} View`;
export const PlaceOfService = "Place of Service";
export const PlacesOfService = "Places of Service";
export const Practitioners = "Practitioners";
export const RecentLocations = (labels: Labeling) => `Recent ${labels.Locations}`;
export const RecentServiceTypes = (labels: Labeling) => `Recent ${labels.ServiceTypes}`;
export const RecommendationSummary = "Recommendation Summary";
export const ReEnterPin = "Confirm Pin";
export const Required = "Required";
export const RequiredTreatmentPlanLevel = "Required treatment plan level";
export const Restore = "Restore";
export const Recipients = "Recipients";
export const Role = "Role";
export const SameAuthorOnly = "Same author only";
export const Saved = "Saved";
export const Schedule = "Schedule";
export const Scheduled = "Scheduled";
export const SearchByLastName = "Search must include last name.";
export const SessionTimeout = "Session Timeout";
export const ShareWith = "Share with";
export const Show = "Show";
export const Signer = "Signer";
export const SignIn = "Sign In";
export const SignOut = "Sign Out";
export const SimilarityCheck = "Similarity check";
export const Status = "Status";
export const Supervisor = "Supervisor";
export const Supervisors = "Supervisors";
export const NonSupervisor = "Non-Supervisor";
export const TextExpansionName = "Name";
export const TextExpansionExpansion = "Expansion";
export const Tracking = "Tracking";
export const Type = "Type";
export const Types = "Types";
export const User = "User";
export const Username = "Username";
export const UserLevel = "User Level";
export const UseUnits = "Use Units";
export const Validations = "Validations";
export const Validation = "Validation";
export const Whoopsie = "Whoopsie!";

// Errors
export const DuplicateNameError = (field: string) => `${field} already exists.`;

// Placeholders
export const ConfirmYourPin = "Confirm your pin";
export const EnterPin = "Enter your pin";
export const RestrictedPatient = (labels: Labeling) => `Restricted ${labels.Patient}`;
export const SearchDropIns = "Search drop-ins...";
export const SearchTextExpansions = `Search ${TextExpansions.toLowerCase()}...`;
export const SearchProductUsers = (labels: Labeling) => `Search ${labels.Product} users...`;
export const SearchUsers = "Search users...";
export const SearchQuestions = "Search Questions...";
export const TypeToSearch = "Type to search";

// Choice Placeholder
export const OptionText = "single select option";

// Messages
export const ClearPinMessage = (name: string) => `Would you like to continue with clearing the signature pin for ${name}?`;
export const MissingPinDialogMessage = "Whoops! It looks like your security pin is missing.";
export const MissingSignatureDialogMessage = "Whoops! It looks like your signature is missing.";
export const CreateNewPinOrSignatureMessage = "Let's create yours so you can sign your notes.";
export const PinLengthMessage = "PIN must be between 5 and 8 characters in length.";
export const ContinueConfirmation = "Are you sure you want to continue?";
export const DeleteConfirmation = (label: string) => `Are you sure you want to delete this ${label.toLowerCase()}?`;
export const DownloadAppGuidance = (deviceSpecificStore: string) => `Please download our official app from the ${deviceSpecificStore} Store for the best experience.`;
export const DuplicateWarning = (label: string) => `Oops, it looks like a ${label} with this name already exists, please update to have a unique ${label} name.`;
export const DuplicateQuestionsWarning = "This note type has duplicate questions in the custom note sections.";
export const DragDropImage = "Drag and drop or click to upload your image";
export const DropImage = "Drop image here to upload";
export const EhrEmptyQuery = "Looks like you haven't searched for a user. Enter the user name to view or import.";
export const EhrNoResult = "No users matched your search.";
export const FetchingData = "Please wait while we retrieve your data...";
export const FetchingDictionary = "Grabbing your personal dictionary...";
export const InvitationSent = "Invitation sent successfully";
export const InvitationsSent = "Invitations sent successfully";
export const Loading = "Please wait...";
export const LoadingProfile = "Please wait while we load your profile...";
export const NoResults = (label: string, capitalized = false) => `No ${capitalized ? label : label.toLowerCase()} found.`;
export const NoNotes = (type: string, showStatus: VisitStatus | null) => `There are no ${type} notes${showStatus ? ` for the ${showStatus} status.` : ""}`;
export const NoSearchResults = (label: string) => `No ${label.toLowerCase()} matched your search.`;
export const NoUsersFound = "There were no users found.";
export const SavedMessage = (label: string) => `The ${label.toLowerCase()} was saved!`;
export const SaveFailure = (label: string) => `Sorry, we could not save the ${label.toLowerCase()}.`;
export const SavingDetails = "Please wait while we save the details...";
export const SigningInLoading = "Please wait while we sign you in...";
export const SigningOutLoading = "Signing out...";
export const SignInPrompt = "Please sign in to continue";
export const UploadImageFailure = "The image upload failed. Please try your upload again.";
export const TextPersonalDictionary = (
  <>
    <p>Improve your Bells experience by adding words to your personal dictionary.</p>
    <p>These words will no longer be flagged as misspellings.</p>
  </>
);
export const TextSnippetCallout = () => (
  <>You can manage your drop-ins in <b>{PersonalSettings}<Icon icon={<ChevronRight />} />Drop-Ins</b></>
);
export const SnippetErrorMessage = "Drop-Ins is required field and only A-Z, a-z, 0-9, -, _ are allowed.";
export const SnippetFullTextErrorMessage = "Full Text is a required field. Please enter text for the drop-in.";
export const LivesImpactedTooltip = (labels: Labeling) => `Number of ${labels.patients} you've provided services to in the selected time period.`;
export const NotesWrittenTooltip = "Total number of notes written for the selected time period.";
export const LateNotesTooltip = (noteSignWarningTimeout: number) => `Number of notes that have exceeded the session to sign ${noteSignWarningTimeout} hour threshold for the selected time period.`;
export const SessionTimeTooltip = "The total session time for signed notes within the selected time period.";

export const UnsupportedBrowser = (isWindows: boolean) => `Oops! Bells is not supported on ${isWindows ? "Windows mobile platform." : "mobile browsers."}`;
export const UnsupportedBrowserHeader = "You seem to be using an unsupported browser";
export const UnsupportedBrowserContent = "Please sign in using one of the following browsers.";
export const UnsavedChangesWarning = "There are unsaved changes, do you wish to discard them?";
export const AllowUserOverride = "Allow user override";
export const DeleteReportEmailSchedule = "Are you sure you want to delete this Report Email Schedule?";
export const ReportScheduleDisabled = "Report schedule disabled because of organization setting preference.";
export const ReportScheduleAuthorDisabled = "Report scheduled disabled because the user that scheduled the report email is no longer enabled in Bells.";
export const LoadingUserProfile = "Please wait, loading profile ..";
export const AppointmentWithEnrollment = (labels: Labeling) => `Appointment in ${labels.Product} must have an ${labels.enrollment} to start the note.`;
export const MaxAmbientListeningUsersWarning = "Your organization has the max number of users for Ambient Listening. Reach out to your Bells Support team to enable additional users.";

// Window Titles
export const NoResultTitle = (label: string) => `No ${label}`;
export const UnsavedChanges = "Unsaved Changes";

// Drop-ins
export const OrgLevelDropInConversionAlertMsg = "Are you sure you want to convert this drop-in to an organization-level drop-in?";
export const CreateDropIn = (level: string) => `Create ${level.toLowerCase()}-level drop-in`;
export const ConvertDropIn = "Convert to organization-level drop-in";
export const DropInOverridden = (overridden: string, overrider: string) => `This ${overridden.toLowerCase()}-level drop-in has been overridden by your ${overrider.toLowerCase()}-level drop-in and will not be used.`;
export const NoDropInsAdded = "No Drop-Ins added";
export const TextSnippetDeleteConfirm = "Are you sure you want to delete this drop-in?";
export const DropInPlaceholderHelperText = 'Use "$" to show placeholders.';
export const ExistingDropIn = "You already have a drop-in with this name";

// Expansions
export const CreateExpansion = (level: string) => `Create ${level.toLowerCase()}-level expansion`;
export const ConvertExpansion = "Convert to organization-level expansion";
export const ExpansionOverridden = (overridden: string, overrider: string) => `This ${overridden.toLowerCase()}-level expansion has been overridden by your ${overrider.toLowerCase()}-level expansion and will not be used.`;
export const NoExpansionsAdded = "No Expansions added";
export const OrgLevelDuplicateNameError = (field: string) => `Organization-level ${field.toLowerCase()} already exists.`;
export const ExistingTextExpansion = "You already have an expansion with this name.";

// Clinical Recommendations
export const AmbiguousNouns = "Ambiguous Nouns";
export const AnyService = "Any Service";
export const AttendantCare = "Attendant Care";
export const AvoidAssessing = "Avoid Assessing";
export const CaseManagement = "Case Management";
export const CPST = "CPST";
export const DiscouragedVerbs = "Discouraged Verbs";
export const ExpandAcronyms = "Expand Acronyms";
export const ForbiddenText = "Forbidden Text";
export const NoRecommendationsAdded = "No recommendations added";
export const OutpatientTherapy = "Outpatient Therapy";
export const PeerSupport = "Peer Support";
export const Recommendations = "Recommendations";
export const RestrictedNouns = "Restricted Nouns";
export const SubstanceUse = "Substance Use";
export const TargetedCaseManagement = "Targeted Case Management";
